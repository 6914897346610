import React, { FC } from 'react'
import AngleRightIcon from '@rsuite/icons/ArrowRightLine'
import Link from 'next/link'

import useUrls from '../services/useUrls'

import styles from '../styles/ArrowLink.module.less'

interface ArrowLinkProps {
  href: string
  text: string
  bold?: boolean
  target?: string
}

const ArrowLink: FC<ArrowLinkProps> = (props) => {
  const { href, text, target, bold = true } = props
  const { urlT } = useUrls()

  return (
    <Link
      target={target}
      className={`${styles['arrow-link']} ${bold ? styles.bold : ''}`}
      href={urlT(href)}
    >
      <span>{text}</span>
      <AngleRightIcon className="margin-left-spacer-half text-color-primary arrow-link-icon" />
    </Link>
  )
}

export default ArrowLink
