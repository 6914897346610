import React, { FC, useMemo } from 'react'
import { Col, FlexboxGrid } from 'rsuite'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { ArrowRightLine } from '@rsuite/icons'
import Link from 'next/link'

import Carousel from '../../Layouts/Carousel'
import { Supplier } from '../../../utils/types/Product'
import { useApi } from '../../../services/useApi'
import { ResourceTag, SUPPLIERS_TO_FETCH_ON_CLIENT } from '../../../utils/constants'
import { getSupplierDeliveryDetails } from '../../../lib/service'
import { getCountryAndLocaleStrings } from '../../../utils/locales'
import SupplierCard from './SupplierCardLanding'
import useUrls from '../../../services/useUrls'
import { getProductsCount } from '../../../lib/product'
import ViewAllCard from './ViewAllCard'
import { extractBrandRows } from '../../../lib/supplier'

import supplierCarouselStyles from '../../../styles/LandingPage/SuppliersCarousel.module.less'

const SuppliersCarousel: FC = () => {
  const { urlT } = useUrls()
  const { getResource, getResourceCount } = useApi()
  const { locale: countryAndLocale } = useRouter()
  const { t } = useTranslation('landing')

  const { data: suppliers } = getResource<Supplier>(ResourceTag.supplier,
    `include=media,text,supplier/address,supplier/service&sort=supplier.address.position&page[limit]=${SUPPLIERS_TO_FETCH_ON_CLIENT}`)

  const { data: productsAggregate } = getResourceCount(ResourceTag.product, 'aggregate=index.supplier.id')

  const { country } = getCountryAndLocaleStrings(countryAndLocale)

  const suppliersForCurrentCountry = suppliers.filter((supplier) => (
    getSupplierDeliveryDetails(supplier, country).isAvailable
  ))

  const [brandsFirstRow, brandsSecondRow] = useMemo(
    () => extractBrandRows(suppliersForCurrentCountry),
    [suppliersForCurrentCountry.length],
  )

  return (
    <section className={supplierCarouselStyles['suppliers-carousel-wrapper']}>
      <FlexboxGrid
        justify="space-between"
        align="bottom"
        className="margin-bottom-spacer-double"
      >
        <FlexboxGrid.Item className="flex-shrink-1">
          <h1 className="margin-botton-zero">{t('Purchase from leading B2B brands in Europe')}</h1>
          <p className={supplierCarouselStyles['description-text']}>
            {t('Find and order from Europe’s leading B2B brands with their own price terms, minimum order quantities and volume prices – all in one place')}
          </p>
          <h4 className={supplierCarouselStyles['sub-heading']}>{t('10,000 SKUs from Europe’s leading B2B brands')}</h4>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item
          as={Col}
          xsHidden
          className={supplierCarouselStyles['browse-brands']}
        >
          <Link href={urlT('/suppliers')}>
            {t('Browse brands')}
            <ArrowRightLine />
          </Link>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <div className={supplierCarouselStyles['supplier-carousel']}>
        {/* Magic number to show scroll offset only when carousel items meet container boundary */}
        <Carousel
          horizontalScrollIconOffset={-50}
          reRenderDependency={suppliersForCurrentCountry.length}
        >
          <div>
            <FlexboxGrid className="flex-flow-row">
              <ViewAllCard />
              {brandsFirstRow.map((supplier) => (
                <SupplierCard
                  key={supplier['supplier.code']}
                  supplierName={supplier['supplier.label']}
                  value={supplier['supplier.code']}
                  productsCount={getProductsCount(productsAggregate, supplier)?.attributes}
                  media={supplier.media}
                />
              ))}
            </FlexboxGrid>
            <FlexboxGrid className="margin-top-spacer padding-top-spacer-half flex-flow-row">
              {brandsSecondRow.map((supplier) => (
                <SupplierCard
                  key={supplier['supplier.code']}
                  supplierName={supplier['supplier.label']}
                  value={supplier['supplier.code']}
                  media={supplier.media}
                  productsCount={getProductsCount(productsAggregate, supplier)?.attributes}
                />
              ))}
            </FlexboxGrid>
          </div>
        </Carousel>
      </div>
    </section>
  )
}

export default SuppliersCarousel
