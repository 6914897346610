import { FC, useEffect, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Button } from 'rsuite'

import { TOP_BUTTON_OFFSET } from '../../utils/constants'
import UpCarrotIcon from '../Icons/UpCarrotIcon'

import styles from '../../styles/CatalogLayout.module.less'

const BackToTopButton: FC = () => {
  const { t } = useTranslation('products')

  const [renderBackToTopButton, setRenderBackToTopButton] = useState(false)

  useEffect(() => {
    const handelScroll = () => {
      if (window.pageYOffset > TOP_BUTTON_OFFSET) {
        setRenderBackToTopButton(true)
      }
      if (window.pageYOffset < TOP_BUTTON_OFFSET) {
        setRenderBackToTopButton(false)
      }
    }
    window.addEventListener('scroll', handelScroll)

    return () => {
      window.removeEventListener('scroll', handelScroll)
    }
  }, [])

  const handleScrollToTopClick = () => window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })

  if (!renderBackToTopButton) {
    return null
  }

  return (
    <Button
      appearance="subtle"
      className={styles['back-to-top']}
      onClick={handleScrollToTopClick}
    >
      <span className="margin-right-spacer padding-right-spacer-half">
        {t('common:back to top')}
      </span>
      <UpCarrotIcon />
    </Button>
  )
}

export default BackToTopButton
