import React, { FC } from 'react'

import ProductIconContainer from '../ImportedProducts/ProductIconContainer'

interface FallBackCategoryImgProps{
  height?: string
  width?: string
  isImportedProduct?: boolean
}

const FallBackCategoryImg: FC<FallBackCategoryImgProps> = (props) => {
  const { height = '60', width = '60', isImportedProduct } = props

  if (isImportedProduct) {
    return <ProductIconContainer className="margin-left-spacer" />
  }
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.9999 59.9997C46.5683 59.9997 59.9997 46.5683 59.9997 29.9999C59.9997 13.4314 46.5683 0 29.9999 0C13.4314 0 0 13.4314 0 29.9999C0 46.5683 13.4314 59.9997 29.9999 59.9997Z"
        fill="url(#paint0_linear_9653_107533)"
        fillOpacity="0.75"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.9998 53.9974C42.9859 57.7661 36.7535 59.9993 29.9999 59.9993C23.2463 59.9993 17.0139 57.7661 12 53.9974V20.7686C12 17.4549 14.6863 14.7686 18 14.7686H41.9998C45.3135 14.7686 47.9998 17.4548 47.9998 20.7686V53.9974Z"
        fill="white"
      />
      <path
        d="M34.875 19.3848H25.125C24.5037 19.3848 24 19.798 24 20.3078C24 20.8176 24.5037 21.2309 25.125 21.2309H34.875C35.4963 21.2309 36 20.8176 36 20.3078C36 19.798 35.4963 19.3848 34.875 19.3848Z"
        fill="#E2E2E2"
      />
      <rect
        x="23.9922"
        y="29.5381"
        width="12"
        height="12"
        rx="1"
        fill="#EEEDED"
      />
      <rect
        x="20.2266"
        y="25.7686"
        width="19.5385"
        height="19.5385"
        rx="2"
        stroke="#D7D7D7"
        strokeWidth="2"
      />
      <rect
        x="23.9922"
        y="55.3848"
        width="12"
        height="12"
        rx="1"
        fill="#EEEDED"
      />
      <rect
        x="20.2266"
        y="51.6152"
        width="19.5385"
        height="19.5385"
        rx="2"
        stroke="#D7D7D7"
        strokeWidth="2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9653_107533"
          x1="29.9999"
          y1="0"
          x2="29.9999"
          y2="59.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F2F3F3" />
          <stop
            offset="1"
            stopColor="#D9DADA"
          />
        </linearGradient>
      </defs>
    </svg>

  )
}

export default FallBackCategoryImg
