import { FC } from 'react'

import { BLACK_COLOR } from '../../utils/constants'

const UpCarrotIcon: FC = () => (
  <svg
    width="10"
    height="7"
    viewBox="0 0 10 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.86612 6.13172L5 3.26658L2.13388 6.13172C1.64573 6.61987 0.854272 6.61987 0.366117 6.13172C-0.122039 5.64356 -0.122039 4.85211 0.366117 4.36395L4.11612 0.61395C4.30387 0.426198 4.53649 0.310658 4.77946 0.267331L4.92621 0.25L5.07379 0.25C5.36867 0.267331 5.65858 0.388648 5.88388 0.61395L9.63388 4.36395C10.122 4.85211 10.122 5.64356 9.63388 6.13172C9.14573 6.61987 8.35427 6.61987 7.86612 6.13172Z"
      fill={BLACK_COLOR}
    />
  </svg>

)

export default UpCarrotIcon
