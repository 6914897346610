import useTranslation from 'next-translate/useTranslation'
import { FC } from 'react'
import { FlexboxGrid } from 'rsuite'
import { ArrowRightLine } from '@rsuite/icons'
import Link from 'next/link'

import { removeHomeCategory } from '../../../lib/category'
import Carousel from '../../Layouts/Carousel'
import useCategoryDataStructure from '../../hooks/useCategoryDataStructure'
import CategoryCardLanding from './CategoryCardLanding'
import { filterNoProductCategories } from '../../../lib/product'
import { useApi } from '../../../services/useApi'
import { ResourceTag } from '../../../utils/constants'
import useUrls from '../../../services/useUrls'

import styles from '../../../styles/LandingPage/CarouselLandingPage.module.less'

interface MainCategoriesCarouselProps {
  label?: string
  className?: string
  hideArrowsFade?: boolean
}

const MainCategoriesCarousel: FC<MainCategoriesCarouselProps> = ({
  label,
  className = '',
  hideArrowsFade = false,
}) => {
  const { getResourceCount } = useApi()
  const { categories } = useCategoryDataStructure()
  const { urlT } = useUrls()

  const { data: productsAggregate } = getResourceCount(ResourceTag.product, 'aggregate=index.catalog.id')
  const filteredCategories = filterNoProductCategories(productsAggregate, categories)

  const { t } = useTranslation('landing')

  const titleClassName = `${styles['carousel-title']} h4`

  return (
    <div className={`${className} ${styles['landing-carousel-wrapper']}`}>
      {label && (
        <div className={`${styles['title-wrapper']}`}>
          <p className={titleClassName}>
            {label}
          </p>
          <Link
            href={urlT('/products')}
            className="font-semibold"
          >
            {t('Browse products')}
            <ArrowRightLine />
          </Link>
        </div>
      )}
      <FlexboxGrid className={styles['carousel-base-styles']}>
        <Carousel hideArrowsFade={hideArrowsFade}>
          {removeHomeCategory(filteredCategories).map((category) => (
            <CategoryCardLanding
              key={category['catalog.code']}
              categoryName={category['catalog.label']}
              value={category['catalog.code']}
              media={category.media}
            />
          ))}
        </Carousel>
      </FlexboxGrid>
    </div>
  )
}

export default MainCategoriesCarousel
