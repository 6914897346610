import { FC } from 'react'

import { BLACK_COLOR } from '../../utils/constants'

import contactStyles from '../../styles/ContactModal.module.less'

interface NextArrowIconProps{
  height?: string
  color?: string
}

const NextArrowIcon: FC<NextArrowIconProps> = ({ height = '18', color = BLACK_COLOR }) => (
  <svg
    className={contactStyles['next-arrow-icon']}
    width="12"
    height={height}
    viewBox="0 0 11 17"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.01306 0.946128C1.26963 0.661052 1.68844 0.608834 2.00358 0.803471L2.11639 0.888058L9.92888 7.91931C10.2354 8.19517 10.2695 8.65305 10.0311 8.96936L9.92888 9.0807L2.11639 16.112C1.79567 16.4006 1.3017 16.3746 1.01306 16.0539C0.756489 15.7688 0.748524 15.3468 0.975167 15.0539L1.07113 14.9506L8.23751 8.49844L1.07113 2.04946C0.786052 1.79289 0.733834 1.37408 0.928471 1.05893L1.01306 0.946128Z" />
  </svg>
)

export default NextArrowIcon
